import React, { useEffect, useState } from 'react'
import CheckList from './CheckList'
import axios from '../../../utils/axios';
import { appSubmission, createCheckListHeading, deleteCheckListHeading, updateCheckListHeading } from '../../../utils/urls';
import FlexComponent from '../../FlexComponent';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Calendar } from 'primereact/calendar';

function CheckListContainer({ userId, formId, usersList, checkListsData, handleClose, toast }) {

    //   const [checkLists, setCheckLists] = useState(checkListsData)
    const [allCheckLists, setAllCheckLists] = useState(checkListsData)
    const [addCheckListTitle, setAddCheckListTitle] = useState(false)
    const [title, setTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const [datetime12h, setDateTime12h] = useState(null);

    const handleSaveCheckList = () => {

        const data = {
            name: title
        }
        console.log(data)
        const Url = `${createCheckListHeading}${formId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    getAllCheckLists()
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })
        setTitle('');
    };

    const handleUpdateCheckList = (titleName, checkListId) => {
        const data = {
            name: titleName,
            due_date : datetime12h
        }
        console.log(data)
        const Url = `${updateCheckListHeading}${checkListId}`
        axios.post("Url", data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setAllCheckLists((prev) => (
                        prev.map(list => {
                            if (list.id === checkListId) {
                                return { ...list, name: titleName };
                            } else {
                                return list;
                            }
                        })
                    ))
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })
        setTitle('');
    };


    const handleDeleteCheckList = (checkListId) => {
        confirmDialog({
            message: 'Are you sure you want to delete this Check List?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                // const updatedData = allCheckLists?.filter(item => item.id !== checkListId);
                // setAllCheckLists(updatedData);
                const Url = `${deleteCheckListHeading}${checkListId}`
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            getAllCheckLists()
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            handleClose()
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        handleClose()
                    })
                
            },
            reject : ()=>{
                console.log("")
            }
        });

    };

    const getAllCheckLists = () => {
        setLoading(true)
        const listUrl = `${appSubmission}${formId}`
        axios.get(listUrl, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setAllCheckLists(response.data.checklist_heading)
                    setLoading(false)
                    // setSelectedLists(response.data)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })
    }

    // useEffect(() => {
    //     getAllCheckLists()
    // },[])

    return (
        <div key={loading} >
            <ConfirmDialog />
            {/* <div className="flex-auto">
                <label htmlFor="calendar-12h" className="font-bold block mb-2">
                    12h Format
                </label>
                <Calendar id="calendar-12h" value={datetime12h} onChange={(e) => setDateTime12h(e.value)} showTime hourFormat="12" />
            </div> */}
            <FlexComponent justifyContent="start" gap="1rem" >
                <p><i className="pi pi-check-square" ></i><span style={{ marginLeft: "7px" }} >CheckList</span> </p>
                <Button style={{ cursor: "pointer", padding:"0.4rem 0.5rem", border:"1px solid grey" }} onClick={() => {
                    setAddCheckListTitle(!addCheckListTitle)
                }} > <i className="pi pi-plus"></i> </Button>
            </FlexComponent>
            {addCheckListTitle && <div style={{ padding: "1rem" }} > <FlexComponent gap="1rem" >
                <InputText placeholder='Enter the title' style={{ width: '100%', padding: ".3rem" }} id="title" value={title} onChange={(e) => {
                    setTitle(e.target.value)
                }} />
                <Button style={{ padding: ".3rem .6rem", border:"1px solid grey" }} onClick={handleSaveCheckList} >Add</Button>
            </FlexComponent>
                <Divider />
            </div>
            }
            {allCheckLists?.map((list, index) => (
                <div key={index} >
                    {/* <h3>{list.name}</h3> */}
                    <CheckList userId={userId} checkListHeading={list.name} checkListsData={list} handleUpdateCheckList={handleUpdateCheckList} handleDeleteCheckList={handleDeleteCheckList} usersList={usersList} formId={formId} toast={toast}  handleClose={handleClose} />
                </div>
            ))}

        </div>
    )
}

export default CheckListContainer