import React, { useRef, useState, useEffect } from 'react';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import AppItem from './AppItem';
import SortableAppItem from './SortableAppItem';
import { ProgressSpinner } from 'primereact/progressspinner';
import FlexComponent from '../../FlexComponent';
import axios from '../../../utils/axios';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import './apps.css'
import { appSubmission, appSubmissionListByStage, getCustomFields, getOpportunitiesListByStage } from '../../../utils/urls';
import { InputText } from 'primereact/inputtext';
import Activity from './Activity';
import CheckList from './CheckList';
import AppPopup from './AppPopup';


function mergeRefs(...refs) {
  return (element) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    });
  };
}



const AppSection = ({ userId, toast, id, pipeline, apps, usersList, language, labels, companies, selectedUsers, incomeRangeValue, creditRangeValue, savingsRangeValue, stageIds, stageAppCounts, setTotal, setBoardSections, searchQuery, loading }) => {
  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id,
  });
  const customRef = useRef(null);
  // const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false)
  const [popupLoad, setPopupLoad] = useState(false)
  const previousOffsetRef = useRef(0);
  const [isEmpty, setIsEmpty] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [applicantData, setApplicantData] = useState()
  const [allCustomFields, setAllCustomFields] = useState([])

  const getAllCustomFields = () => {
    const listUrl = `${getCustomFields}`
    axios.get(listUrl, {
        headers: { 'Content-Type': 'application/json' }
    })
        .then((response) => {
            if (response.status === 200) {
                // console.log(response.data)
                setAllCustomFields(response.data)
            }
        })
        .catch((error) => {
            // setAppLeads(null)
        })
}

  const handleClick = (formId) => {
    setIsOpen(true);
    setPopupLoad(true)
    getAllCustomFields()
    const url = `${appSubmission}${formId}?pipelineId=${pipeline.id}`
    axios.get(url, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data)
          const data = response.data
          setApplicantData(data)
          setPopupLoad(false)
        }
      })
      .catch((error) => {
        // setAppLeads(null)
      })
  };



  const handleClose = () => {
    setIsOpen(false);
  };

  

  useEffect(() => {
    const handleScroll = () => {
      if (customRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = customRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 1 && !scrollLoading && !isEmpty) {
          const currentOffset = apps.length;

          if (currentOffset === previousOffsetRef.current) {
            return; // No change in offset, so do not make the API call
          }
          let incomeRange = ""
          let creditRange = ""
          let savingsRange = ""
          if (!incomeRangeValue?.includes(parseInt("0")) || !incomeRangeValue?.includes(parseInt("2000000"))) {
              incomeRange = incomeRangeValue
          }
          if (!creditRangeValue?.includes(parseInt("0")) || !creditRangeValue?.includes(parseInt("1000"))) {
              creditRange = creditRangeValue
          }
          if (!savingsRangeValue?.includes(parseInt("0")) || !savingsRangeValue?.includes(parseInt("2000000"))) {
              savingsRange = savingsRangeValue
          }

          setScrollLoading(true);
          const url = `${getOpportunitiesListByStage}?userId=${userId}&stageId=${stageIds[id]}&offset=${currentOffset}&limit=5&search=${searchQuery || ''}&filterUser=${selectedUsers}&language=${language}&labels=${labels}&company=${companies}&income=${incomeRange}&credit=${creditRange}&available=${savingsRange}`
          axios.get(url, {
            headers: { 'Content-Type': 'application/json' },
          })
            .then((response) => {
              if (response.status === 200) {
                const dataT = response.data;
                // console.log('dataT', dataT)
                const updatedData = Object.keys(dataT).reduce((acc, key) => ({
                  ...acc,
                  [key]: dataT[key]?.map(item => ({ ...item, id: item.form_id }))
                }), {});

                const convertedArray = Object.values(updatedData).flat();
                if (convertedArray.length === 0) {
                  setIsEmpty(true);
                }
                // console.log('convertedArray', convertedArray)

                if (convertedArray.length !== 0) {
                  setBoardSections(prevState => {
                    const existingApps = prevState[id];
                    const newApps = convertedArray.filter(newApp =>
                      !existingApps?.some(existingApp => existingApp.form_id === newApp.form_id)
                    );
                    return {
                      ...prevState,
                      [id]: [
                        ...existingApps,
                        ...newApps
                      ]
                    };
                  });
                  previousOffsetRef.current = currentOffset; // Update the previous offset
                }

              }
              setScrollLoading(false);
            })
            .catch(() => {
              setScrollLoading(false);
            });
        }
      }
    };

    const currentRef = customRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [id, apps, setBoardSections, scrollLoading, isEmpty]);

// console.log(id, "id")


  return (
    <div style={{ borderRadius: '15px', backgroundColor: '#0E123D', padding: "20px", minWidth: '350px' }} className='applicantCardSectionDiv'>
      <p variant="h6" style={{ color: 'white', marginBottom: '10px', whiteSpace: 'nowrap' }}>
        {/* <FlexComponent  >
          <p>{id}</p>
          <p style={{ fontSize: '18px' }}>{stageAppCounts[id] || 0}</p>
        </FlexComponent> */}
        {loading ? <div  className='loadingAppItem' style={{ width: '100%', height: '20px',padding: "0px ", margin :"0px 5px" , border:"none"}}>
          </div> : 
          <FlexComponent>
             <p>{!loading && id}</p>
            <p style={{fontFamily: "DINCondensedC", fontSize: '18px',height:"20px",  }}>{!loading && stageAppCounts[id]}</p>
          </FlexComponent>
          }
      </p>
      <SortableContext
        id={id}
        items={apps}
        strategy={verticalListSortingStrategy}
      >
        <div className='appSection' ref={mergeRefs(setDroppableNodeRef, customRef)} style={{ overflowY: 'scroll', height: '65vh' }}>
          {!loading && apps?.map((app) => (
            <div key={app?.id} style={{ margin: '10px' }} onClick={() => {
              handleClick(app.id)
            }}>
              <SortableAppItem id={app?.id} >
                <AppItem app={app} userId={userId} setBoardSections={setBoardSections} setTotal={setTotal} toast={toast}/>
              </SortableAppItem>
            </div>
          ))}

          {loading &&
            (Array(4).fill("").map((item, index) => (
              <div key={index} className='loadingAppItem' style={{ width: '280px', height: '170px' }}>
              </div>
            )))
          }
          {scrollLoading &&
            <FlexComponent justifyContent="center">
              <ProgressSpinner style={{ width: '40px', height: '40px' }} />
            </FlexComponent>
          }
        </div>
      </SortableContext>
      <Dialog className='applicantPopup ' visible={isOpen} onHide={() => handleClose()}>
        <div style={{ marginTop: '1rem', minHeight: "70vh" }}>
          {!popupLoad ? <AppPopup applicantData={applicantData} pipelineId={pipeline.id} allCustomFields={allCustomFields} usersList={usersList} userId={userId} setBoardSections={setBoardSections} handleClick={handleClick} handleClose={handleClose} toast={toast} /> :
            <FlexComponent flexDirection="column" justifyContent="center" >
              <div style={{ minWidth: "40vw", height:"100%" }} className='loadingAppItem' ></div>
            </FlexComponent>
          }
        </div>
      </Dialog>
    </div>
  );
};

export default AppSection;
