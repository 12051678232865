import { Button } from 'primereact/button'
import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import FlexComponent from '../../FlexComponent';
import { descriptionAdd } from '../../../utils/urls';
import axios from '../../../utils/axios';
import './apps.css'

function Description({ description, usersList, formId, toast }) {
    const [value, setValue] = useState(description);
    const [descValue, setDescValue] = useState(description)
    const [isEdit, setIsEdit] = useState(false)

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            // ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
            // ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    };
// console.log(usersList)

    const stripHtml = (html) => {
        const tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    };

    const handleSave = () => {
        // setSavedContent(value);
        setIsEdit(false)
        
        const data = {
            content: value
        }
        setDescValue(value)
        const url = `${descriptionAdd}${formId}`
        axios.post(url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    // setLoading(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    };

    return (
        <div style={{marginBottom:"1rem"}} >
            <FlexComponent style={{paddingBottom:".5rem"}} >
                <p> <i className='pi pi-align-left' style={{ marginRight: "5px" }} ></i> Description</p>
                {stripHtml(descValue).trim() && <Button style={{padding: ".5rem .6rem", border:"1px solid grey", boxShadow:"none"}} onClick={() => {
                    setIsEdit(true)
                }} >Edit</Button>}
            </FlexComponent>
            {((stripHtml(descValue).trim() === ""  || isEdit)) ?
                <div>
                    <div className='descriptionEditorDiv' style={{height: "140px"}} >
                    <FlexComponent justifyContent="center" style={{marginTop:".6rem" }} >
                        <ReactQuill
                            value={value}
                            onChange={setValue}
                            modules={modules}
                            // rows={3}
                            style={{ height: "80px", width: "92%" }}
                            theme="snow"
                        />
                    </FlexComponent>
                    </div>
                    {!isEdit && <div style={{ marginLeft: "2rem" }} >
                    <Button 
                        disabled={!stripHtml(value).trim()} 
                        style={{ background: "#2FC635"}} onClick={handleSave}>Save</Button>
                        </div>}
                    {isEdit && <div style={{ marginLeft: "2rem" }} >
                        <Button 
                        disabled={!stripHtml(value).trim()} 
                        style={{ background: "#2FC635"}} onClick={handleSave}>Save</Button>
                         <Button style={{ marginLeft: ".5rem" }} onClick={() => {
                            setIsEdit(false)
                        }}>Cancel</Button>
                    </div>}
                </div> :
                <FlexComponent className='descriptionDiv' >
                    <div dangerouslySetInnerHTML={{ __html: descValue }}></div>
                </FlexComponent>
            }
        </div>
    )
}

export default Description