import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { ProgressBar } from 'primereact/progressbar'
import { Mention } from 'primereact/mention'
import { Calendar } from 'primereact/calendar'
import { OverlayPanel } from 'primereact/overlaypanel'
import { MultiSelect } from 'primereact/multiselect'
import { Avatar } from 'primereact/avatar'
import FlexComponent from '../../FlexComponent'
import './apps.css'
import axios from '../../../utils/axios';
import { createCheckList, deleteCheckList, updateCheckList } from '../../../utils/urls'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'

function CheckList({ userId, formId, usersList, checkListsData, handleUpdateCheckList, handleDeleteCheckList, checkListHeading,  handleClose, toast }) {
    const [checkLists, setCheckLists] = useState(checkListsData.items);
    const [suggestions, setSuggestions] = useState([]);

    const [isEditTitle, setIsEditTitle] = useState(false)
    const [editTitle, setEditTitle] = useState(checkListHeading)

    const [isCheckListAdd, setIsCheckListAdd] = useState(false)
    const [value, setValue] = useState('');
    const [mentionedUsers, setMentionedUsers] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState([])
    const [dueDate, setDueDate] = useState('')

    const [isCheckListEdit, setIsCheckListEdit] = useState(false);
    const [editCheckListValue, setEditCheckListValue] = useState(null)
    const [editMentionedUsers, setEditMentionedUsers] = useState([]);
    const [editAssignedUsers, setEditAssignedUsers] = useState([])
    const [editDueDate, setEditDueDate] = useState('')
    const [hideCheckedItems, setHideCheckedItems] = useState(true)

    const [checkListEditIndex, setCheckListEditIndex] = useState(null);
    const [checkListLoad, setCheckListLoad] = useState(false)
    const checkedCount = checkLists.filter(list => list.completed).length;

    const dueDateOp = useRef(null);
    const assignOp = useRef(null);
    const editDueDateOp = useRef(null);
    const editAssignOp = useRef(null);

    const handleAddCheckList = () => {
        setIsCheckListAdd(true)
        setIsCheckListEdit(false)
    }

    function formatDateString(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    }

    const convertDateToDateString = (date) => {
        if (!date) return '';
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    const convertDateStringToDate = (dateString) => {
        if (!dateString) return null;
        const [year, month, day] = dateString.split('-');
        return new Date(year, month - 1, day); // month is 0-based in Date
    };


    const onSearch = (event) => {
        setTimeout(() => {
            const query = event.query.toLowerCase();
            let filteredSuggestions = [];

            if (!query.trim().length) {
                filteredSuggestions = [...usersList];
            } else {
                filteredSuggestions = usersList.filter((user) =>
                    user.label.toLowerCase().startsWith(query)
                );
            }

            setSuggestions(filteredSuggestions);
        }, 250);
    };

    const onChange = (e) => {
        const inputValue = e.target.value;
        setValue(inputValue);
        setMentionedUsers(extractUsers(inputValue));
    };



    const extractUsers = (text) => {
        // Update the regex pattern to match @mentions with periods, underscores, and hyphens
        const mentionPattern = /@([\w.-]+)/g;
        const mentions = [...text.matchAll(mentionPattern)].map(match => match[1]);
        return mentions.map(mention => {
            const user = usersList.find(user => user.label.replace(/\s+/g, '').toLowerCase() === mention.toLowerCase());
            return user ? { id: user.value } : null;
        }).filter(user => user !== null);
    };

    const itemTemplate = (suggestion) => {
        return (
            <div className="flex align-items-center">
                <span className="flex flex-column ml-2">
                    {suggestion.label}
                </span>
            </div>
        );
    };

    const userTemplate = (option) => {
        return (
            <FlexComponent justifyContent="start">
                <Avatar image={option.image} size="small" label={getInitials(option?.label)} shape="circle" style={{ marginRight: '8px' }} />
                <span>{option.label}</span>
            </FlexComponent>
        );
    };

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    const convertDateToString = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // month is 0-based in Date
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSaveCheckList = () => {
        const data = {
            item: value,
            mention: mentionedUsers,
            assigned_user: assignedUsers.map(item => ({ id: item })),
            due_date: convertDateToString(dueDate),
            heading_id : checkListsData.id
        }
        console.log(data)
        setCheckListLoad(true)
        const Url = `${createCheckList}${formId}/${userId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // handleClick(formId)
                    const newCheckListObj = {
                        checklist_id: response.data.checklist_id,
                        ...data
                    }
                    setCheckLists(prev => [newCheckListObj, ...prev]);

                    setCheckListLoad(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })
        setValue('');
        setMentionedUsers([]);
        setAssignedUsers([]);
        setDueDate('')
    };

    // console.log(checkLists)
    const handleSaveEditCheckList = (list) => {
        const data = {
            // checklist_id : list.checklist_id,
            item: editCheckListValue,
            mention: editMentionedUsers.map(item => ({ id: item.id })),
            assigned_user: editAssignedUsers.map(item => ({ id: item })),
            due_date: editDueDate ? convertDateToString(editDueDate) : list.due_date,
            is_completed: list.completed
        }
        console.log(data)
        setIsCheckListEdit(false)
        setCheckLists(prevItems =>
            prevItems.map(item =>
                item.checklist_id === list.checklist_id ? { ...list, ...data } : item
            )
        );
        setIsCheckListAdd(false)
        // setCommentLoad(true)
        const Url = `${updateCheckList}${list.checklist_id}/${userId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // setCommentLoad(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })
        setValue('');
        setMentionedUsers([]);
        setAssignedUsers([]);
        setDueDate('')
    };

    const handleDelete = (list) => {
        const updatedData = checkLists?.filter(item => item.checklist_id !== list.checklist_id);
        setCheckLists(updatedData);
        const Url = `${deleteCheckList}${list.checklist_id}/${userId}`
        axios.post(Url, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {

                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })

    };

    function getNameById(id) {
        const item = usersList.find(obj => obj.value === id);
        return item ? item.label : null; // Return the name if found, otherwise return null
    }

    function getImageById(id) {
        const item = usersList.find(obj => obj.value === id);
        return item ? item.image : null; // Return the name if found, otherwise return null
    }
    // console.log(checkListsData)

    const handleEdit = (index) => {
        setIsCheckListAdd(false)
        setIsCheckListEdit(true);
        setCheckListEditIndex(index);
        setEditCheckListValue(checkLists[index].item);
        setEditAssignedUsers(checkLists[index].assigned_user.map(list => list.id))
        setEditDueDate(convertDateStringToDate(checkLists[index].due_date))
        setEditMentionedUsers(checkLists[index].mention)
        // setEditUsers(extractUsers(commentsData[index].comment));
    };

    const onListChange = (e, list) => {
        const { value, checked } = e.target;
        setCheckLists((prevCheckLists) =>
            prevCheckLists.map((list) =>
                list.checklist_id === value.checklist_id
                    ? { ...list, completed: checked }
                    : list
            )
        );
        const { completed, checklist_id, created_at, created_by_image, assigned_user, created_by_name, mention, ...listData } = list
        const data = {
            ...listData,
            assigned_user: assigned_user.map(item => ({ id: item.id })),
            mention: mention.map(item => ({ id: item.id })),
            is_completed: checked
        }
        console.log(data)
        const Url = `${updateCheckList}${list.checklist_id}/${userId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        }).then((response) => {
            if (response.status === 200) {
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            }
        })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })

    };
    const highlightMentions = (text) => {
        const mentionRegex = /@[\w.-]+/g;
        const parts = text.split(mentionRegex);
        const mentions = text.match(mentionRegex) || [];

        const result = [];
        parts.forEach((part, index) => {
            result.push(part);
            if (mentions[index]) {
                result.push(<span key={index} style={{ color: "#2FC623" }} className="mention">{mentions[index]}</span>);
            }
        });

        return result;
    };

    const calculateCompletionPercentage = () => {
        const total = checkLists.length;
        const completedCount = checkLists.filter(list => list.completed).length;
        const percentage = (completedCount / total) * 100;
        return Math.round(percentage);
    };

    // console.log(hideCheckedItems)
    return (
        <div className='checkListDiv' >
            <FlexComponent gap="1rem" >
                {!isEditTitle ?
                    <FlexComponent justifyContent="start" gap="1rem" >
                        <p><span style={{ marginLeft: "1rem" }} >{checkListHeading}</span> </p>
                        <i style={{ cursor: "pointer" }} onClick={() => {
                            setIsEditTitle(!isEditTitle)
                        }} className='pi pi-pencil'></i>
                    </FlexComponent> :
                    <FlexComponent justifyContent="start" gap="1rem" >
                        <InputText  style={{  padding: ".3rem" }} id="title" value={editTitle} onChange={(e) => {
                            setEditTitle(e.target.value)
                        }} />
                        <i style={{ cursor: "pointer" }} onClick={() => {
                            handleUpdateCheckList(editTitle, checkListsData.id)
                            setIsEditTitle(!isEditTitle)
                        }} className='pi pi-save'></i>
                    </FlexComponent>
                }
                <FlexComponent justifyContent="end" gap="1rem" style={{ paddingRight: "1rem" }} >
                    <Button style={{ boxShadow: "none", padding: "0.4rem 1rem", border:"1px solid grey" }} onClick={() => {
                        setHideCheckedItems(!hideCheckedItems)
                    }}
                    >{hideCheckedItems ? `Show Checked Items (${checkedCount})` : 'Hide Checked Items'}</Button>
                    <i style={{ cursor: "pointer" }} onClick={() => {
                        handleDeleteCheckList(checkListsData.id)
                    }} className='pi pi-trash'></i>

                </FlexComponent>
            </FlexComponent>
            <div style={{ padding: "0 1rem" }} >
                <div className="card" style={{ borderRadius: "20px", marginTop: "0.5rem" }} >
                    <ProgressBar value={calculateCompletionPercentage()}></ProgressBar>
                </div>
            </div>
            <div style={{ marginTop: ".5rem" }} >
                <div>
                    {checkLists.map((list, index) => (
                        <div key={list.checklist_id} style={{ padding: "0.4rem 1rem", display: hideCheckedItems && list.completed ? 'none' : '' }} >
                            {/* <Checkbox inputId={list.checklist_id} name="list" value={list} onChange={onListChange} checked={list.completed} />
                            <span htmlFor={list.checklist_id} onClick={() => {

                            }} style={{ marginLeft: "0.5rem", textDecoration: list.completed ? 'line-through' : '', cursor: "pointer" }} >
                                {list.item}
                            </span> */}
                            <FlexComponent className='checkListItem' alignItems="start" gap=".3rem" style={{ padding: "0.5rem", border: '1px solid grey', borderRadius: ".5rem", }}>
                                <Checkbox inputId={list.checklist_id} name="list" value={list} onChange={(event) => {
                                    onListChange(event, list)
                                }} checked={list.completed} />
                                {!isCheckListEdit || checkListEditIndex !== index ? (
                                    <FlexComponent className="applicantContainer" justifyContent="start" >
                                        <div className="applicantDivs" htmlFor={list.checklist_id} style={{ display: "flex", justifyContent: "space-between", width: "100%", marginLeft: "0.5rem", cursor: "pointer" }} >
                                            <div className="applicantDivs" onClick={() => {
                                                handleEdit(index)
                                            }} style={{ width: "65%", textDecoration: list.completed ? 'line-through' : '' }}> {highlightMentions(list.item)}</div>
                                            <FlexComponent className='fullWidth' gap="1rem" style={{ width: "35%" }} >
                                                <div onClick={() => {
                                                    handleEdit(index)
                                                }}>{list?.due_date ? <span style={{ fontSize: "14px" }} ><i className="pi pi-clock" style={{ marginRight: '5px' }}></i>{formatDateString(list?.due_date)}</span> : ''}</div>
                                                <div onClick={() => {
                                                    handleEdit(index)
                                                }}>
                                                    {/* {list.assigned_user.length === 0 && <i className="pi pi-user-plus" style={{ marginRight: '5px' }}></i>} */}
                                                    {list.assigned_user?.map((user, index) => (
                                                        <Avatar key={index} style={{ fontSize: "14px", width: '25px', height: '25px', color: 'black', marginRight: "2px" }} image={getImageById(user?.id)} shape='circle' label={getInitials(getNameById(user.id))} />
                                                    ))}
                                                </div>
                                                <div><i onClick={() => {
                                                    handleDelete(list)
                                                }} className="pi pi-trash" style={{ marginRight: '5px' }}></i></div>
                                            </FlexComponent>
                                        </div>
                                    </FlexComponent>
                                ) : (
                                    <div className="applicantDivs" style={{ width: "100%" }} >
                                        <Mention
                                            value={editCheckListValue}
                                            onChange={(e) => {
                                                setEditCheckListValue(e.target.value)
                                            }}
                                            suggestions={suggestions}
                                            onSearch={onSearch}
                                            field="label"
                                            placeholder="Edit comment..."
                                            // rows={1}
                                            cols={50}
                                            itemTemplate={itemTemplate}
                                            className="edit-mention-input"
                                            style={{ width: "98%", marginLeft: "0.5rem" }}
                                        />
                                        <FlexComponent >
                                            <FlexComponent gap="0.5rem" justifyContent="start" style={{ marginTop: ".5rem" }} >
                                                <Button style={{ padding: "0.4rem 1rem", background: "#2FC635", border:"1px solid grey" }} onClick={() => {
                                                    handleSaveEditCheckList(list)
                                                }} >Save</Button>
                                                <Button style={{ padding: "0.4rem 1rem", border:"1px solid grey" }} onClick={() => {
                                                    setIsCheckListEdit(false)
                                                    setEditAssignedUsers([])
                                                    setEditCheckListValue('');
                                                    setEditDueDate('')
                                                }} >Cancel</Button>
                                            </FlexComponent>
                                            <FlexComponent justifyContent="center" gap="1rem" >
                                                <FlexComponent style={{ border: "none", boxShadow: "none" }} justifyContent="start" >
                                                    <span onClick={(e) => editAssignOp.current.toggle(e)} style={{display:"flex", alignItems:"center", cursor: "pointer" }} ><i className="pi pi-user-plus" style={{ marginRight: '5px' }}></i>
                                                        {editAssignedUsers.length > 0 ? (
                                                            <>
                                                                {editAssignedUsers.map((id, index) => (
                                                                    <Avatar style={{ fontSize: "14px", width: '25px', height: '25px', color: 'black', marginRight: "2px" }} image={getImageById(id)} shape='circle' label={getInitials(getNameById(id))} />
                                                                ))}
                                                                {/* {editAssignedUsers.length > 1 && <span style={{ marginLeft: ".2rem", padding: ".2rem", background: "white", color: "black", borderRadius: "25px" }}>+{editAssignedUsers.length - 1}</span>} */}
                                                            </>
                                                        ) :
                                                            'Assign'}
                                                    </span>
                                                </FlexComponent>
                                                <FlexComponent style={{ border: "none", boxShadow: "none" }} justifyContent="start" >
                                                    <span style={{ cursor: "pointer" }}
                                                        onClick={(e) => editDueDateOp.current.toggle(e)}
                                                    ><i className="pi pi-clock" style={{ marginRight: '5px' }}></i>
                                                        {list.due_date ? <span>{editDueDate ? convertDateToDateString(editDueDate) : list.due_date}</span> : <span>Due Date</span>}
                                                    </span>
                                                </FlexComponent>
                                            </FlexComponent>
                                        </FlexComponent>
                                        <OverlayPanel ref={editAssignOp} style={{ background: '#1A2B53', maxWidth: "400px" }} showCloseIcon>
                                            <MultiSelect
                                                value={editAssignedUsers}
                                                options={usersList}
                                                itemTemplate={userTemplate}
                                                style={{ width: '100%', color: 'black' }}
                                                onChange={(event) => {
                                                    setEditAssignedUsers(event.value)
                                                }}
                                                placeholder='Select Members'
                                                filter
                                            />
                                        </OverlayPanel>
                                        <OverlayPanel ref={editDueDateOp} style={{ background: '#1A2B53' }} >
                                            <Calendar id="buttondisplay" value={editDueDate} inline onChange={(e) => {
                                                setEditDueDate(e.value)
                                                editDueDateOp.current.toggle(e)
                                            }} />
                                        </OverlayPanel>
                                    </div>
                                )}
                            </FlexComponent>
                        </div>
                    ))}
                </div>
                <Button onClick={handleAddCheckList} style={{ marginLeft: "1rem", padding: "0.4rem 1rem", border:"1px solid grey" }}>Add an Item</Button>
                <div style={{ margin: "1rem 0" }} >
                    {isCheckListAdd &&
                        <div  >
                            <Mention
                                value={value}
                                onChange={onChange}
                                suggestions={suggestions}
                                onSearch={onSearch}
                                field="label"
                                placeholder="Add an Item"
                                // rows={1}
                                // cols={50}
                                itemTemplate={itemTemplate}
                                style={{ width: "100%" }}
                                className="mention-input"
                            />
                            <FlexComponent >
                                <FlexComponent gap="0.5rem" justifyContent="start" style={{ width: "55%", marginTop: ".5rem" }} >
                                    <Button style={{ padding: "0.4rem 1rem", background: "#2FC635", border:"1px solid grey" }} disabled={!dueDate} onClick={() => {
                                        handleSaveCheckList()
                                    }} >Add</Button>
                                    <Button style={{ padding: "0.4rem 1rem", border:"1px solid grey" }} onClick={() => {
                                        setIsCheckListAdd(false)
                                        setAssignedUsers([])
                                        setValue('')
                                        setDueDate('')
                                    }} >Cancel</Button>
                                </FlexComponent>
                                <FlexComponent justifyContent="center" gap="1rem" >
                                    <FlexComponent style={{ border: "none", boxShadow: "none" }} justifyContent="start" >
                                        <span onClick={(e) => assignOp.current.toggle(e)} style={{ cursor: "pointer" }} ><i className="pi pi-user-plus" style={{ marginRight: '5px' }}></i>
                                            {assignedUsers.length > 0 ? (
                                                <>
                                                    {assignedUsers.map((id, index) => (
                                                        <>
                                                            {index == 0 && <span key={index}>{getNameById(id)}</span>}
                                                        </>
                                                    ))}
                                                    {assignedUsers.length > 1 && <span style={{ marginLeft: ".2rem", padding: ".2rem", background: "white", color: "black", borderRadius: "25px" }}>+{assignedUsers.length - 1}</span>}
                                                </>
                                            ) :
                                                'Assign'}
                                        </span>
                                    </FlexComponent>
                                    <FlexComponent style={{ border: "none", boxShadow: "none" }} justifyContent="start" >
                                        <span style={{ cursor: "pointer" }}
                                            onClick={(e) => dueDateOp.current.toggle(e)}
                                        ><i className="pi pi-clock" style={{ marginRight: '5px' }}></i>
                                            {dueDate ? <span>{convertDateToDateString(dueDate)}</span> : <span>Due Date</span>}
                                        </span>
                                    </FlexComponent>
                                </FlexComponent>

                            </FlexComponent>
                            <OverlayPanel ref={assignOp} style={{ background: '#1A2B53', maxWidth: "400px" }} showCloseIcon>
                                <MultiSelect
                                    value={assignedUsers}
                                    options={usersList}
                                    itemTemplate={userTemplate}
                                    style={{ width: '100%', color: 'black' }}
                                    onChange={(event) => {
                                        setAssignedUsers(event.value);
                                    }}
                                    placeholder='Select Members'
                                    filter
                                />
                            </OverlayPanel>
                            <OverlayPanel ref={dueDateOp} style={{ background: '#1A2B53' }} >
                                <Calendar id="buttondisplay" value={dueDate} inline onChange={(e) => {
                                    setDueDate(e.value)
                                    dueDateOp.current.toggle(e)
                                }} />
                            </OverlayPanel>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CheckList
