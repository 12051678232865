import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import axios from '../utils/axios';
import { appSubmission, getNotifications, notificationOpened, registeredUserList } from '../utils/urls';
import logo from '../Images/homes_sale_logo.png';
import { Toast } from 'primereact/toast';
import FlexComponent from '../components/FlexComponent';
import { Dialog } from 'primereact/dialog';
import AppPopup from '../components/apps/appsKanban/AppPopup';
import './Nav.css'
import { Badge } from 'primereact/badge';
import { InputSwitch } from 'primereact/inputswitch';
import { useParams } from 'react-router-dom';

export const NotificationComponent = ({ setBoardSections }) => {
    const [permission, setPermission] = useState(() => {
        return 'Notification' in window ? Notification?.permission : 'unsupported';
    });
    const { pipelineId } = useParams();
    const [notifications, setNotifications] = useState([]);
    const userId = useSelector((state) => state.user.userId);
    const notifyOp = useRef(null);
    const latestNotificationRef = useRef(null);
    const toast = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [applicantData, setApplicantData] = useState()
    const [popupLoad, setPopupLoad] = useState(false)
    const [registeredUsersList, setRegisteredUsersList] = useState([])
    const [hideReadItems, setHideReadItems] = useState(false)
    const [checked, setChecked] = useState(false);
    const readCount = notifications.filter(notification => !notification.read).length;

    const requestPermission = () => {
        if (!("Notification" in window)) {
            return;
        }
        if ("Notification" in window) {
            if (permission !== 'granted') {
                Notification?.requestPermission().then(permission => {
                    setPermission(permission);
                });
            }
        }
    };

    const fetchNotifications = async (userId) => {
        try {
            const response = await axios.get(`${getNotifications}${userId}`, {
                headers: { 'Content-Type': 'application/json' }
            });
            if (response.status === 200) {
                const notifications = response.data.notifications;
                setNotifications(notifications);
                const latest = notifications[0];
                // console.log("latest", latest)
                if ("Notification" in window) {
                    if (Notification?.permission === "granted") {
                        showNotification(latest.message, latest.created_at);
                        toast.current.show({ severity: 'info', detail: latest.message, life: 3000 });
                    }
                } else {
                    toast.current.show({ severity: 'info', detail: latest.message, life: 3000 });
                }
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };
    const loadNotifications = async () => {
        if (userId) {
            try {
                const response = await axios.get(`${getNotifications}${userId}`, {
                    headers: { 'Content-Type': 'application/json' }
                });
                if (response.status === 200) {
                    const notificationList = response.data.notifications;
                    setNotifications(notificationList);

                }
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        }

    };

    const markAsRead = async (notificationId) => {
        if (userId) {
            // const updatedData = notifications.filter(item => item.id !== notificationId)
            // setNotifications(updatedData)
            setNotifications((prev) =>
                prev.map((list) =>
                    list.id === notificationId
                        ? { ...list, read: true }
                        : list
                )
            );
            try {
                const response = await axios.get(`${notificationOpened}${notificationId}`, {
                    headers: { 'Content-Type': 'application/json' }
                });
                if (response.status === 200) {
                    // const notifications = response.data.notifications;
                    // setNotifications(notifications);
                }
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        }

    };
    const handleMessage = useCallback((message) => {
        try {
            const parsedMessage = JSON.parse(message.data);
            console.log(parsedMessage)
            const user_id = parsedMessage.message.userId;
            console.log("userTrue", user_id === userId)
            if (user_id === userId) {
                fetchNotifications(userId);
            }
        } catch (error) {
            console.error('Error parsing message:', error);
        }
    }, [userId]);

    const { sendMessage, lastMessage, readyState } = useWebSocket('wss://backendapi.homesforsalemedia.com/ws/notifications/', {
        onOpen: () => console.log('Connected to WebSocket'),
        onMessage: handleMessage,
        onClose: () => console.log('Disconnected from WebSocket'),
        onError: (error) => console.error('WebSocket error:', error),
        shouldReconnect: () => true,
        reconnectAttempts: 10,
        reconnectInterval: 3000,
    });

    const showNotification = (message, createdTime) => {
        console.log(createdTime)
        const options = {
            body: 'You have a new notification',
            icon: logo,
            tag: createdTime
        };

        // Close the previous notification if it exists
        if (latestNotificationRef.current) {
            latestNotificationRef.current.close();
        }

        // Show the new notification and store the reference
        if ("Notification" in window) {
        const notification = new Notification(message, options);
        latestNotificationRef.current = notification;
        }
        // notification.onclick = () => {
        //     window.open('http://localhost:3000/');
        // };
    };

    const handleClick = (formId, notificationId) => {
        setIsOpen(true);
        setPopupLoad(true)
        markAsRead(notificationId)
        const url = `${appSubmission}${formId}?pipelineId=${pipelineId || 1}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    const data = response.data
                    setApplicantData(data)
                    setPopupLoad(false)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })

        const registeredUsersUrl = `${registeredUserList}`
        axios.get(registeredUsersUrl, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    const options = response?.data?.map((user) => ({
                        label: user.name,
                        value: user.user_id,
                        image: user.image
                    })) || [];
                    setRegisteredUsersList(options)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        requestPermission();
        loadNotifications()
    }, [userId]);

    return (
        <div
        >
            <Toast ref={toast} />
            <Button onClick={(e) => {
                notifyOp.current.toggle(e)
                setHideReadItems(true)
                loadNotifications()
            }} style={{ boxShadow: "none", marginRight: ".5rem" }}>
                {/* <i className="pi pi-bell" style={{ fontSize: "22px" }}></i> */}
                <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '2rem' }}>
                    <Badge style={{ background: "red", color: "white" }} value={readCount}></Badge>
                </i>
            </Button>
            <OverlayPanel ref={notifyOp} showCloseIcon dismissable={false} style={{ background: '#1A2B53' }}>
                <div className='appSection' style={{ height: "55vh", overflowY: 'scroll', width: "30vw", marginTop: "1rem" }} >
                    {/* <FlexComponent gap="1rem" > */}
                    {/* <Button style={{ boxShadow: "none", padding: "0.4rem 1rem" }} onClick={() => {
                        setHideReadItems(!hideReadItems)
                    }}
                    >{hideReadItems ? `Show Read Notifications` : 'Hide Read Notifications'}</Button> */}
                    <FlexComponent style={{ padding: "5px" }} justifyContent="end" gap="1rem" >
                        <label style={{ color: "white" }} htmlFor="">Only show unread</label>
                        <InputSwitch style={{ boxShadow: "none", border: "none" }} checked={hideReadItems} onChange={(e) => setHideReadItems(e.value)} />
                    </FlexComponent>

                    {/* </FlexComponent> */}
                    {notifications?.length > 0 && notifications?.map((notification, index) => (
                        <div key={index} >
                            {hideReadItems && !notification.read && <FlexComponent key={index} className='notificationList' style={{
                                borderBottom: "1px solid grey",
                                // display: hideReadItems && notification.read ? 'none' : 'flex' 
                            }} >
                                <div  style={{ width: "90%", padding: ".5rem", cursor: "pointer" }} onClick={() => {
                                    handleClick(notification.form_id, notification.id)
                                }} >
                                    <p style={{ color: "white" }} >{notification.message}</p>
                                    <p style={{ color: "#d2d0d0", fontSize: "12px" }} >{notification.created_at}</p>
                                </div>
                                <div style={{ width: "8%" }} >
                                    {!notification.read && <i onClick={() => {
                                        markAsRead(notification.id)
                                    }} className="pi pi-check" style={{ cursor: "pointer", color: "white", marginRight: '5px' }}></i>}
                                </div>
                            </FlexComponent>}
                            {!hideReadItems && <FlexComponent key={index} className='notificationList' style={{
                                borderBottom: "1px solid grey",
                                // display: hideReadItems && notification.read ? 'none' : 'flex' 
                            }} >
                                <div style={{ width: "90%", padding: ".5rem", cursor: "pointer" }} onClick={() => {
                                    handleClick(notification.form_id, notification.id)
                                }} >
                                    <p style={{ color: "white" }} >{notification.message}</p>
                                    <p style={{ color: "#d2d0d0", fontSize: "12px" }} >{notification.created_at}</p>
                                </div>
                                <div style={{ width: "8%" }} >
                                    {!notification.read && <i onClick={() => {
                                        markAsRead(notification.id)
                                    }} className="pi pi-check" style={{ cursor: "pointer", color: "white", marginRight: '5px' }}></i>}
                                </div>
                            </FlexComponent>}

                        </div>
                    ))}
                    {hideReadItems && !readCount && <p style={{ color: "white", fontSize: "1.5rem" }} >No unread Notifications</p>}
                </div>
            </OverlayPanel>
            <Dialog className='applicantPopup ' visible={isOpen} onHide={() => handleClose()}>
                <div style={{ marginTop: '1rem', minHeight: "70vh" }}>
                    {!popupLoad ? <AppPopup applicantData={applicantData} pipelineId={pipelineId} setBoardSections={setBoardSections} usersList={registeredUsersList} userId={userId} handleClick={handleClick} handleClose={handleClose} toast={toast} /> :
                        <FlexComponent flexDirection="column" justifyContent="center" >
                            <div style={{ minWidth: "40vw", height: "100%" }} className='loadingAppItem' ></div>
                        </FlexComponent>
                    }
                </div>
            </Dialog>
        </div>
    );
};

// export default NotificationComponent;
