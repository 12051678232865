// import { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Purchase from "./pages/purchase/Purchase";
import Team from "./pages/team/Team";
import Dasboard from "./pages/dashboard/Dashboard";
import LanguagePage from "./pages/language/LanguagePage";
import Settings from "./pages/settings/Settings";
import Login from "./pages/login/Login";
import Campaign from "./pages/campaign/Campaign";
import Participant from "./pages/participant/Participant"
import TeamLeader from "./pages/teamLeaders/TeamLeaders"
import UserTeams from "./pages/userTeams/UserTeams"
import Users from "./pages/users/Users"
import AppLeads from "./pages/app/AppLeads";
import AppKanban from "./pages/app/AppKanban";
import { BubblyContainer } from "./library/src";
import ProfileView from "./pages/profileView/ProfileView";
import Form from "./pages/form/Form";
import NewBuyerAppForm from "./pages/form/NewBuyerAppForm";
import NewLoanSetUpForm from "./pages/form/NewLoanSetUpForm";
import LeaderBoard from "./pages/app/LeaderBoard";
import ShoppersLeaderBoard from "./pages/app/ShoppersLeaderBoard";
// import Language from "./components/languages/Language";

function App() {
  return (
    <BrowserRouter>
      <BubblyContainer />
      <Routes>
        <Route path="/login" element={<Login/>} />
        <Route path="/" element={<Dasboard/>} />
        <Route path="/team" element={<Team />} />
        <Route path="/participant" element={<Participant/>} />
        <Route path="/users/:locationId" element={<Team />} />
        <Route path="/campaign" element={<Campaign />} />
        <Route path="/campaign_users/:campaignId" element={<Campaign />} />
        <Route path="/campaign_fallback_users/:campaignId" element={<Campaign />} />
        <Route path="/purchase" element={<Purchase />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/language" element={<LanguagePage />} />
        <Route path="/teams" element={<UserTeams />} />
        <Route path="/leader" element={<TeamLeader />} />
        <Route path="/users" element={<Users />} />
        <Route path="/app" element={<AppLeads/>} />
        <Route path="/app-kanban" element={<AppKanban/>} />
        <Route path="/app-kanban/:pipelineId" element={<AppKanban/>} />
        <Route path="/app/:contactId/:formId" element={<ProfileView />} />
        <Route path="/new-app" element={<Form />} />
        <Route path="/new-buyer-app" element={<NewBuyerAppForm />} />
        <Route path="/new-loan-set-up-form" element={<NewLoanSetUpForm />} />
        <Route path="/callers-leaderboard" element={<LeaderBoard />} />
        <Route path="/shoppers-leaderboard" element={<ShoppersLeaderBoard />} />
        <Route path="*" element={<>No Match</>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
