import { useEffect, useContext, useState, useRef } from 'react'
import { Nav } from "../../shared/Nav";
import { useNavigate } from 'react-router-dom';
import { UpdateAppStage, UpdateLeadStage, appKanbanUserList, appSubmission, appSubmissionList, getCompanies, getLabel, getOpportunitiesList, getPipelines, registeredUserList, verifyToken } from '../../utils/urls';
import jwt_decode from "jwt-decode";
import axios from '../../utils/axios'
import { CancelToken } from 'axios'
import { RefreshToken } from '../../utils/refreshToken';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../utils/userSlice';
import { useSelector } from 'react-redux'
import AppsTable from '../../components/apps/appsTable';
import AppSectionList from '../../components/apps/appsKanban/AppSectionList';
import FlexComponent from '../../components/FlexComponent';
import './appLeads.css'
import { InputText } from 'primereact/inputtext';
import useDebounce from '../../components/apps/appsKanban/hooks/useDebounce';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import ArchivedAppsTable from '../../components/apps/ArchivedAppsTable';
import { Slider } from 'primereact/slider';


const AppKanban = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    // const { isLoggedIn, setIsLoggedIn } = useContext(UserIsLoggedIn);
    const { pipelineId } = useParams();
    // const {userId} = useParams();
    const isSuperuser = useSelector((state) => state.user.isSuperuser);
    const image = useSelector((state) => state.user.image);
    const userLable = useSelector((state) => state.user.userLable);
    const userName = useSelector((state) => state.user.userName);
    const userId = useSelector((state) => state.user.userId);
    const is_leader = useSelector((state) => state.user.is_leader);

    const [scrolling, setScrolling] = useState(false);
    const [loading, setLoading] = useState(false)
    const cancelTokenRef = useRef(null);
    const toast = useRef(null);
    const [isMounted, setIsMounted] = useState(false)

    // const [pipelineId, setPipelineId] = useState()
    const [boardSections, setBoardSections] = useState({
        "⏲️ Waiting/Follow Up": [],
        "🤳 Self-Serve Quick App": [],
        "📃✨ New Application": [],
        "🤖 Auto Doc Request": [],
        "🔥 Prequalified Awaiting Docs": [],
        "📄 Docs in - Active Client": [],
        "✅ Preapproved": [],
        "🛒Shopping": [],
        "📈 Refi": [],
        "✅ Active Loan": []
    });
    const [pipeline, setPipeline] = useState('')
    const [stageIds, setStageIds] = useState({})
    const [stageAppCounts, setStageAppCounts] = useState({})
    const [usersList, setUsersList] = useState([])
    const [registeredUsersList, setRegisteredUsersList] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [labels, setLabels] = useState([])
    const [selectedLabels, setSelectedLabels] = useState([])
    const [companies, setCompanies] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedLanguages, setSelectedLanguages] = useState([])
    // const [incomeRangeValue, setIncomeRangeValue] = useState([0, 1000000])
    // const { searchQuery, setSearchQuery } = useDebounce(handleSearch, setLoading);
    const [searchQuery, setSearchQuery] = useState('');
    const [incomeRangeValue, setIncomeRangeValue] = useState([0, 2000000])
    const [creditRangeValue, setCreditRangeValue] = useState([0, 1000])
    const [savingsRangeValue, setSavingsRangeValue] = useState([0, 2000000])

    const debouncedSearchQuery = useDebounce(searchQuery, 300);
    const debouncedIncomeRange = useDebounce(incomeRangeValue, 300);
    const debounceCreditRange = useDebounce(creditRangeValue, 300);
    const debounceSavingsRange = useDebounce(savingsRangeValue, 300);

    const options = ['Board', 'Table', 'Archived'];
    // const [value, setValue] = useState(options[0]);
    const [page, setPage] = useState(options[0])
    const op = useRef(null);
    const [total, setTotal] = useState(0);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    const userTemplate = (option) => {
        return (
            <FlexComponent justifyContent="start">
                <Avatar image={option.image} size="small" label={<span style={{ padding: '5px' }}>{getInitials(option?.label)}</span>} shape="circle" style={{ marginRight: '8px' }} />
                <span>{option.label}</span>
            </FlexComponent>
        );
    };

    const labelsTemplate = (option) => {
        return (
            <FlexComponent justifyContent="center" style={{ height: "30px", width: "200px", background: `#${option.color}`, color: "white" }} >
                <p>{option.label}</p>
            </FlexComponent>
        );
    };

    function formatNumber(number) {
        // Convert the input to a number
        if (number) {
            const num = parseInt(number, 10);

            if (isNaN(num)) {
                throw new Error('Invalid number');
            }

            if (num >= 1000 && num < 1000000) {
                return (num / 1000).toFixed(1) + 'k';
            } else if (num >= 1000000 && num < 1000000000) {
                return (num / 1000000).toFixed(1) + 'M';
            } else if (num >= 1000000000) {
                return (num / 1000000000).toFixed(1) + 'B';
            } else {
                return num.toString();
            }
        }
    }


    useEffect(() => {

        const authTokens = JSON.parse(localStorage.getItem('authTokens'))
        const refresh = authTokens?.refresh
        if (refresh) {
            const body = JSON.stringify({
                token: refresh,
            });
            axios
                .post(verifyToken, body, {
                    headers: { "Content-Type": "application/json" },
                })
                .then((response) => {
                    if (response.status === 200) {
                        const access = authTokens?.access
                        const decodedToken = jwt_decode(access)
                        const superUser = decodedToken.is_superuser
                        dispatch(setUser({ userId: decodedToken.user_id, isSuperuser: decodedToken.is_superuser, userLable: decodedToken.userLable, image: decodedToken.image, userName: decodedToken.userName, ghlUserId: decodedToken.ghlUserId, is_leader: decodedToken.is_leader }));
                        localStorage.setItem("prevUrl", window.location.pathname);
                        // console.log(decodedToken.is_superuser)
                        // if (decodedToken.is_superuser === true){
                        //   localStorage.setItem("prevUrl", window.location.pathname);
                        // }
                        // else{
                        //   localStorage.removeItem('authTokens');
                        //   localStorage.removeItem('prevUrl');
                        //   navigate('/login')
                        // }

                    }
                })
                .catch((err) => {
                    localStorage.removeItem('authTokens');
                    localStorage.removeItem('prevUrl');
                    dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
                    // dispatch(change({ id: null ,username: null, image: null }));
                    // setIsLoggedIn(false)
                    navigate('/login')
                });
        } else {
            localStorage.removeItem('authTokens');
            localStorage.removeItem('prevUrl');
            dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
            // dispatch(change({ id: null ,username: null, image: null }));
            // setIsLoggedIn(false)
            navigate('/login')
        }
    }, [])


    useEffect(() => {
        const intervalId = setInterval(async () => {
            await RefreshToken();
        }, 3600000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure to log out?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('authTokens');
                localStorage.removeItem('prevUrl');
                // dispatch(change({ username: null, image: null }));
                navigate('/login')
            }
        })
    }

    const handleStageUpdate = (contact_id, form_id, stage, prevStage, emails) => {
        console.log(stage, prevStage)
        const data = {
            // stage: stage,
            userId: userId,
            stage_id: stageIds[stage],
            email_list : emails
        }
        const stageUrl = `${UpdateLeadStage}${contact_id}/${form_id}`
        axios.post(stageUrl, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Updated', life: 3000 });
                    // handleSearch()
                    setStageAppCounts(prevCounts => ({
                        ...prevCounts,
                        [stage]: prevCounts[stage] + 1,
                        [prevStage]: prevCounts[prevStage] - 1
                    }))
                    const url = `${appSubmission}${form_id}?pipelineId=${pipelineId}`
                    axios.get(url, {
                        headers: { 'Content-Type': 'application/json' }
                    })
                        .then((response) => {
                            if (response.status === 200) {
                                // console.log(response.data)
                                const data = response.data
                                setBoardSections(prevSections => {
                                    const updatedSections = { ...prevSections };
                                    for (const sectionId in updatedSections) {
                                        updatedSections[sectionId] = updatedSections[sectionId].map(item => {
                                            if (item.form_id === form_id) {
                                                return {
                                                    ...item,
                                                    due_date: data.due_date
                                                };
                                            }
                                            return item;
                                        });
                                    }
                                    return updatedSections;
                                });
                            }
                        })

                }
                else if (response.status === 208) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                    handleSearch()
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleSearch()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleSearch()
            })
    }

    const getAllPipelines = () => {
        if (pipelineId) {
            const url = `${getPipelines}?userId=${userId}`
            axios.get(url, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        const item = response?.data?.find(item => item.id == pipelineId);
                        console.log("item", item)
                        setPipeline(item)
                    }
                })
                .catch((error) => {
                    // setAppLeads(null)
                })
        }
    }


    const handleSearch = () => {
        if (userId && pipelineId) {
            setLoading(true)
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Operation canceled due to new request.');
            }
            cancelTokenRef.current = CancelToken.source();
            let incomeRange = ""
            let creditRange = ""
            let savingsRange = ""
            if (!incomeRangeValue?.includes(parseInt("0")) || !incomeRangeValue?.includes(parseInt("2000000"))) {
                incomeRange = incomeRangeValue
            }
            if (!creditRangeValue?.includes(parseInt("0")) || !creditRangeValue?.includes(parseInt("1000"))) {
                creditRange = creditRangeValue
            }
            if (!savingsRangeValue?.includes(parseInt("0")) || !savingsRangeValue?.includes(parseInt("2000000"))) {
                savingsRange = savingsRangeValue
            }
            // const users = selectedUsers?.join(',')
            getAllPipelines()
            const url = `${getOpportunitiesList}?userId=${userId}&pipelineId=${pipelineId}&search=${searchQuery}&filterUser=${selectedUsers}&language=${selectedLanguages}&labels=${selectedLabels}&company=${selectedCompanies}&income=${incomeRange}&credit=${creditRange}&available=${savingsRange}`

            axios.get(url, {
                headers: { 'Content-Type': 'application/json' },
                cancelToken: cancelTokenRef.current.token
            })
                .then((response) => {
                    if (response.status === 200) {
                        setLoading(true)
                        // console.log(response.data)
                        const stagesData = response.data.stages
                        const stagesCount = response.data.counts
                        const stageIdsData = response.data.stage_ids
                        const updatedData = Object.keys(stagesData).reduce((acc, key) => ({
                            ...acc,
                            [key]: stagesData[key]?.map(item => ({ ...item, id: item.form_id }))
                        }), {});

                        setBoardSections(updatedData)
                        setStageAppCounts(stagesCount)
                        setTotal(Object.values(stagesCount).reduce((sum, count) => sum + count, 0))
                        setStageIds(stageIdsData)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    // setAppLeads(null)
                })
        }
    };



    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const loadUsersData = () => {
        const url = `${appKanbanUserList}?userId=${userId}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    const options = response?.data?.map((user) => ({
                        label: user.name,
                        value: user.user_id,
                        image: user.image
                    })) || [];
                    setUsersList(options)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })

        const registeredUsersUrl = `${registeredUserList}`
        axios.get(registeredUsersUrl, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    const options = response?.data?.map((user) => ({
                        label: user.name,
                        value: user.user_id,
                        image: user.image
                    })) || [];
                    setRegisteredUsersList(options)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })

        const labelsUrl = `${getLabel}`
        axios.get(labelsUrl, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    const options = response?.data?.map((label) => ({
                        label: label.name,
                        value: label.id,
                        color: label.color
                    })) || [];
                    setLabels(options)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })

        const companyUrl = `${getCompanies}`
        axios.get(companyUrl, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    const options = response?.data?.map((option) => ({
                        label: option.title,
                        value: option.title,
                    })) || [];
                    setCompanies(options)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })

    };

    const clearFilters = () => {
        setSearchQuery('');
        setSelectedUsers([])
        setSelectedLanguages([])
        setSelectedLabels([])
        setSelectedCompanies([])
        setIncomeRangeValue([0, 2000000])
        setCreditRangeValue([0, 1000])
        setSavingsRangeValue([0, 2000000])
        setLoading(true)
        handleSearch()
    }

    useEffect(() => {
        if (userId && pipelineId) {
            setLoading(true)
            handleSearch()
        }
    }, [userId, isMounted, pipelineId, selectedUsers, selectedLanguages, selectedLabels, selectedCompanies, debouncedSearchQuery, debouncedIncomeRange, debounceCreditRange, debounceSavingsRange])

    useEffect(() => {
        if (userId) {
            loadUsersData()
        }
    }, [userId])

    return (
        <>
            <Toast ref={toast} />
            <header className={scrolling ? 'sticky' : ''}>
                <Nav handleLogout={handleLogout} isSuperuser={isSuperuser} setBoardSections={setBoardSections} userLable={userLable} image={image} userName={userName} is_leader={is_leader} />
            </header>
            <div className='kanbanContainer' style={{ background: 'rgb(65 112 188)', paddingBottom: '2rem' }}>
                {<Button className='piplelineTitleMob' style={{ cursor: "auto", color: "white", background: "transparent", boxShadow: "none", border: "none", width: "100%" }} >{pipeline.name} {!loading && `- ${total}`}</Button>}
                <FlexComponent flexDirection="column">
                    <FlexComponent className='searchContainer ' gap="2rem" justifyContent="end" style={{ margin: '10px 20px 10px 0px' }} >
                        {page === 'Board' && <>
                            {<Button className='piplelineTitle' style={{ cursor: "auto", color: "white", background: "transparent", boxShadow: "none", border: "none", width: "30%" }} >{pipeline.name} {!loading && `- ${total}`}</Button>}
                            <div  >
                                <Button type="button" onClick={(e) => op.current.toggle(e)} style={{ boxShadow: "none", borderRadius: '0', paddingRight: (searchQuery.trim() || selectedUsers?.length > 0 || selectedLanguages?.length > 0 || selectedCompanies?.length > 0 || selectedLabels?.length > 0 || (!incomeRangeValue?.includes(parseInt("0")) || !incomeRangeValue?.includes(parseInt("2000000"))) || (!creditRangeValue?.includes(parseInt("0")) || !creditRangeValue?.includes(parseInt("1000"))) || (!savingsRangeValue?.includes(parseInt("0")) || !savingsRangeValue?.includes(parseInt("2000000"))) ) && "5px" }} >
                                    <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.61799 6C3.87461 6 3.39111 6.78231 3.72356 7.44721L3.99996 8H20L20.2763 7.44721C20.6088 6.78231 20.1253 6 19.3819 6H4.61799ZM10.8618 17.7236C10.9465 17.893 11.1196 18 11.309 18H12.6909C12.8803 18 13.0535 17.893 13.1382 17.7236L14 16H9.99996L10.8618 17.7236ZM17 13H6.99996L5.99996 11H18L17 13Z" fill="currentColor"></path></svg>
                                    Filter
                                    {(searchQuery?.trim() || selectedUsers?.length > 0 || selectedLanguages?.length > 0 || selectedCompanies?.length > 0 || selectedLabels?.length > 0 || (!incomeRangeValue?.includes(parseInt("0")) || !incomeRangeValue?.includes(parseInt("2000000"))) || (!creditRangeValue?.includes(parseInt("0")) || !creditRangeValue?.includes(parseInt("1000"))) || (!savingsRangeValue?.includes(parseInt("0")) || !savingsRangeValue?.includes(parseInt("2000000"))) ) && <Badge style={{ marginTop: "4px" }} value={!loading && total} severity="info">{!loading && total}</Badge>}
                                </Button>
                                {(searchQuery?.trim() || selectedUsers?.length > 0 || selectedLanguages?.length > 0 || selectedCompanies?.length > 0 || selectedLabels?.length > 0 || (!incomeRangeValue?.includes(parseInt("0")) || !incomeRangeValue?.includes(parseInt("2000000"))) || (!creditRangeValue?.includes(parseInt("0")) || !creditRangeValue?.includes(parseInt("1000"))) || (!savingsRangeValue?.includes(parseInt("0")) || !savingsRangeValue?.includes(parseInt("2000000"))) ) && <Button style={{ boxShadow: "none", padding: "13px", borderRadius: '0', paddingLeft: "0" }} onClick={clearFilters} >Clear All</Button>}
                            </div>
                            <OverlayPanel ref={op} style={{ background: '#1A2B53', }} >
                                <div className='applicantDivs filterPopup' style={{ width: '25vw', height: '60vh', overflowY: "auto" }} >
                                    <FlexComponent flexDirection="column" gap="1rem" style={{marginBottom: "1rem"}} >
                                        <div className='applicantDivs' style={{ width: "90%" }} >
                                            <p style={{ color: "white", marginBottom: ".5rem" }} >Keyword</p>
                                            <span className="p-input-icon-left " >
                                                <i className="pi pi-search" />
                                                <InputText type="search" placeholder='Enter a Keyword...' onChange={handleSearchChange} value={searchQuery} style={{ color: 'black' }} />
                                            </span>
                                            <p style={{ fontSize: "12px", color: "white", marginTop: "5px" }} >Search Location names, Contact names and more</p>
                                        </div>
                                        <div className='applicantDivs' style={{ width: "90%" }}>
                                            <p style={{ color: "white", marginBottom: ".5rem" }} >Members</p>
                                            <MultiSelect
                                                value={selectedUsers}
                                                options={usersList}
                                                itemTemplate={userTemplate}
                                                style={{ width: '100%', color: 'black' }}
                                                onChange={(event) => {
                                                    setSelectedUsers(event.value)
                                                    setLoading(true)
                                                }}
                                                placeholder='Select Members'
                                                filter
                                            />
                                        </div>
                                        <div className='applicantDivs' style={{ width: "90%" }}>
                                            <p style={{ color: "white", marginBottom: ".5rem" }} >Language</p>
                                            <MultiSelect
                                                value={selectedLanguages}
                                                options={[
                                                    { label: "English", value: "English" },
                                                    { label: "Spanish", value: "Spanish" },
                                                    { label: "Spanish & Some English", value: "Spanish and Some English" },
                                                    { label: "Other", value: "Other" }
                                                ]}
                                                // itemTemplate={userTemplate}
                                                style={{ width: '100%', color: 'black' }}
                                                onChange={(event) => {
                                                    setSelectedLanguages(event.value)
                                                    setLoading(true)
                                                }}
                                                placeholder='Select Language'
                                            // filter
                                            />
                                        </div>

                                        <div className='applicantDivs' style={{ width: "90%" }}>
                                            <p style={{ color: "white", marginBottom: ".5rem" }} >Company</p>
                                            <MultiSelect
                                                value={selectedCompanies}
                                                options={companies}
                                                // itemTemplate={userTemplate}
                                                style={{ width: '100%', color: 'black' }}
                                                onChange={(event) => {
                                                    setSelectedCompanies(event.value)
                                                    setLoading(true)
                                                }}
                                                placeholder='Select Company'
                                            // filter
                                            />
                                        </div>


                                        <div className='applicantDivs' style={{ width: "90%" }}>
                                            <p style={{ color: "white", marginBottom: ".5rem" }} >Labels</p>
                                            <MultiSelect
                                                value={selectedLabels}
                                                options={labels}
                                                itemTemplate={labelsTemplate}
                                                style={{ width: '100%', color: 'black' }}
                                                onChange={(event) => {
                                                    setSelectedLabels(event.value)
                                                    setLoading(true)
                                                }}
                                                placeholder='Select Labels'
                                            // filter
                                            />
                                        </div>

                                        <div className='applicantDivs' style={{ width: "90%" }}>
                                            <p style={{ color: "white", marginBottom: ".5rem" }} >Income Range - {formatNumber(incomeRangeValue[0]) || '0'} to {formatNumber(incomeRangeValue[1])}</p>
                                            <div style={{ padding: "15px", border:"1px solid white", borderRadius:"5px" }} >
                                                <Slider min={0} max={2000000} value={incomeRangeValue} onChange={(e) => setIncomeRangeValue(e.value)} className="w-14rem" range />
                                            </div>
                                        </div>

                                        <div className='applicantDivs' style={{ width: "90%" }}>
                                            <p style={{ color: "white", marginBottom: ".5rem" }} >Credit Range - {creditRangeValue[0] || '0'} to {creditRangeValue[1]}</p>
                                            <div style={{ padding: "15px", border:"1px solid white", borderRadius:"5px" }} >
                                                <Slider min={0} max={1000} value={creditRangeValue} onChange={(e) => setCreditRangeValue(e.value)} className="w-14rem" range />
                                            </div>
                                        </div>

                                        <div className='applicantDivs' style={{ width: "90%" }}>
                                            <p style={{ color: "white", marginBottom: ".5rem" }} >Available Range - {formatNumber(savingsRangeValue[0]) || '0'} to {formatNumber(savingsRangeValue[1])}</p>
                                            <div style={{ padding: "15px", border:"1px solid white", borderRadius:"5px" }} >
                                                <Slider min={0} max={2000000} value={savingsRangeValue} onChange={(e) => setSavingsRangeValue(e.value)} className="w-14rem" range />
                                            </div>
                                        </div>

                                    </FlexComponent>
                                </div>
                            </OverlayPanel>
                        </>}
                        <div>
                            <Button style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0", fontWeight: "600", background: page === 'Board' ? '#0F173E' : 'white', color: page === 'Board' ? 'white' : 'black' }} onClick={() => {
                                setPage('Board')
                                handleSearch()
                            }}>Board</Button>
                            <Button style={{ borderTopLeftRadius: "0", borderRadius: isSuperuser ? "0" : "0 .5rem .6rem 0", fontWeight: "600", background: page === 'Table' ? '#0F173E' : 'white', color: page === 'Table' ? 'white' : 'black' }} onClick={() => {
                                setPage('Table')
                            }}>Table</Button>
                            {isSuperuser && <Button style={{ borderRadius: "0 .5rem .6rem 0", fontWeight: "600", background: page === 'Archived' ? '#0F173E' : 'white', color: page === 'Archived' ? 'white' : 'black' }} onClick={() => {
                                setPage('Archived')
                            }}>Archived</Button>}
                        </div>

                        {/* <SelectButton value={page} onChange={(e) => setPage(e.value)} options={options} /> */}
                    </FlexComponent>
                    {page === 'Board' ? <FlexComponent flexDirection="column" className='applicant-cards' alignItems='start' gap="0.2rem" style={{ zIndex: '1' }}  >
                        <FlexComponent flexDirection='column' style={{ gap: '2rem', zIndex: '1', width: 'auto' }}>
                            <AppSectionList key={loading} toast={toast} userId={userId} pipeline={pipeline} usersList={registeredUsersList} setTotal={setTotal} language={selectedLanguages} labels={selectedLabels} companies={selectedCompanies} incomeRangeValue={incomeRangeValue} creditRangeValue={creditRangeValue} savingsRangeValue={savingsRangeValue} stageAppCounts={stageAppCounts} stageIds={stageIds} setStageAppCounts={setStageAppCounts} selectedUsers={selectedUsers} boardSections={boardSections} setBoardSections={setBoardSections} handleStageUpdate={handleStageUpdate} searchQuery={searchQuery} isMounted={isMounted} setIsMounted={setIsMounted} loading={loading} />
                        </FlexComponent>
                    </FlexComponent>
                        : page === 'Table' ?
                            <AppsTable userId={userId} /> :
                            <ArchivedAppsTable userId={userId} />
                    }
                </FlexComponent>
            </div>
        </>
    );
}
export default AppKanban;