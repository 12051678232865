import React, { useEffect, useState } from 'react';
// import Grid from '@mui/material/Grid';
import {
    useSensors,
    useSensor,
    PointerSensor,
    KeyboardSensor,
    DndContext,
    closestCorners,
    DragOverlay,
    defaultDropAnimation,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates, arrayMove } from '@dnd-kit/sortable';
import AppSection from './AppSection';
import AppItem from './AppItem';
import FlexComponent from '../../FlexComponent';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Avatar } from 'primereact/avatar';
import { Chips } from 'primereact/chips';

const findBoardSectionContainer = (boardSections, id) => {
    if (id in boardSections) {
        return id;
    }

    const container = Object.keys(boardSections).find((key) =>
        boardSections[key].find((item) => item.form_id === id)
    );
    return container;
};

const AppSectionList = ({ userId, toast, boardSections, pipeline, usersList, selectedUsers, language, labels, companies, incomeRangeValue, creditRangeValue, savingsRangeValue, stageAppCounts, stageIds, setTotal, setStageAppCounts, setBoardSections, handleStageUpdate, searchQuery, isMounted, setIsMounted, loading }) => {
    const [activeAppId, setActiveAppId] = useState(null);
    const [visible, setVisible] = useState(false)
    const [emails, setEmails] = useState([])
    const [appDetails, setAppDetails] = useState()
    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            Add CC Emails
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Cancel" style={{ background: "red" }} onClick={() => {
                setVisible(false)
                setIsMounted(!isMounted)
                // handleStageUpdate(app.ghl_contact_id, app.form_id, overContainer, app.app_status)
                setEmails([])
            }} autoFocus />
            <Button label="Continue" icon="pi pi-check" onClick={() => {
                setVisible(false)
                handleStageUpdate(appDetails.ghl_contact_id, appDetails.form_id, appDetails.overContainer, appDetails.app_status, emails)
                setEmails([])
            }} autoFocus />
        </div>
    );

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 1 }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = ({ active }) => {
        setActiveAppId(active.id);
    };
    // console.log(boardSections)

    const getAppById = (apps, id) => {
        for (const itemId in apps) {
            const items = apps[itemId];
            for (const item of items) {
                if (item.id === id) {
                    return item;
                }
            }
        }
    };



    const handleDragOver = ({ active, over }) => {
        const activeContainer = findBoardSectionContainer(boardSections, active.id);
        const overContainer = findBoardSectionContainer(boardSections, over?.id);

        if (!activeContainer || !overContainer || activeContainer === overContainer) {
            return;
        }

        // console.log('handleDragOver','||' ,'activeContainer: ', activeContainer , 'overContainer: ',overContainer)

        setBoardSections((boardSection) => {
            // console.log(boardSection)
            const activeItems = boardSection[activeContainer];
            const overItems = boardSection[overContainer];
            const activeIndex = activeItems.findIndex((item) => item.id === active.id);
            const overIndex = overItems.findIndex((item) => item.id !== over?.id);
            // console.log('handleDragOver ||', 'activeIndex: ', activeIndex, 'overIndex: ', overIndex )
            return {
                ...boardSection,
                [activeContainer]: [
                    ...boardSection[activeContainer].filter((item) => item.id !== active.id),
                ],
                [overContainer]: [
                    ...boardSection[overContainer].slice(0, overIndex),
                    boardSections[activeContainer][activeIndex],
                    ...boardSection[overContainer].slice(overIndex, boardSection[overContainer].length),
                ],
            };
        });
    };

    const handleDragEnd = ({ active, over }) => {
        // console.log('handleDragEnd','||' ,'active: ', active , 'over: ',over)
        const activeContainer = findBoardSectionContainer(boardSections, active.id);
        const overContainer = findBoardSectionContainer(boardSections, over?.id);

        if (!activeContainer || !overContainer || activeContainer !== overContainer) {
            return;
        }

        // console.log('handleDragEnd','||' ,'activeContainer: ', activeContainer , 'overContainer: ',overContainer)

        const activeIndex = boardSections[activeContainer].findIndex((app) => app.id === active.id);
        const overIndex = boardSections[overContainer].findIndex((app) => app.id === over?.id);
        if (activeIndex !== overIndex) {
            setBoardSections((boardSection) => ({
                ...boardSection,
                [overContainer]: arrayMove(boardSection[overContainer], activeIndex, overIndex),
            }));
        }
        // console.log(app)
        if (app.app_status != overContainer) {
            console.log("app.app_status", app.app_status)
            console.log("overContainer122", overContainer)
            if (overContainer === "🤖 Auto Doc Request") {
                // Swal.fire({
                //     title: "Are you sure you want to update the app to 'Auto Doc Request'?",
                //     icon: 'warning',
                //     showCancelButton: true,
                //     confirmButtonColor: '#3085d6',
                //     cancelButtonColor: '#d33',
                //     confirmButtonText: 'Yes',
                //     }).then((result) => {
                //         if (result.isConfirmed) {
                //             handleStageUpdate(app.ghl_contact_id, app.form_id, overContainer, app.app_status)
                //         }else{
                //             setIsMounted(!isMounted)
                //         }
                //     })
                setAppDetails({ ...app, overContainer });
                setVisible(true)

            } else {
                handleStageUpdate(app.ghl_contact_id, app.form_id, overContainer, app.app_status)
            }
        }

        const updateStatusInSection = (section, itemId, newStatus) => {
            setBoardSections((prevBoardSections) => {
                if (section in prevBoardSections) {
                    const sectionIndex = prevBoardSections[section]?.findIndex((item) => item?.id === itemId);
                    if (sectionIndex !== -1) {
                        const updatedSection = [...prevBoardSections[section]];
                        updatedSection[sectionIndex].app_status = newStatus;
                        return {
                            ...prevBoardSections,
                            [section]: updatedSection,
                        };
                    }
                }
                return prevBoardSections;
            });
        };
        updateStatusInSection(overContainer, app.id, overContainer)
        setActiveAppId(null);
    };

    //   const dropAnimation = defaultDropAnimation;

    const app = activeAppId ? getAppById(boardSections, activeAppId) : null;

    return (
        <div >
            {/* <ConfirmDialog /> */}
            <Dialog visible={visible} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => {
                if (!visible) return; setVisible(false);
                setIsMounted(!isMounted)
            }}>
                <div>Emails</div>
                <div className="card p-fluid">
                    <Chips style={{ width: "100%" }} value={emails} onChange={(e) => setEmails(e.value)} />
                </div>
            </Dialog>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCorners}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
            >
                <FlexComponent gap='2rem' style={{ color: 'black', width: '100%', padding: '0 15px 15px 15px' }} className='applicantCardInnerDiv' alignItems='start' container spacing={4}>
                    {Object?.keys(boardSections)?.map((boardSectionKey) => (
                        <FlexComponent item key={boardSectionKey} >
                            <AppSection
                                id={boardSectionKey}
                                userId={userId}
                                pipeline={pipeline}
                                stageAppCounts={stageAppCounts}
                                setTotal={setTotal}
                                stageIds={stageIds}
                                selectedUsers={selectedUsers}
                                apps={boardSections[boardSectionKey]}
                                setBoardSections={setBoardSections}
                                searchQuery={searchQuery}
                                usersList={usersList}
                                language={language}
                                incomeRangeValue={incomeRangeValue}
                                creditRangeValue={creditRangeValue}
                                savingsRangeValue={savingsRangeValue}
                                labels={labels}
                                companies={companies}
                                loading={loading}
                                toast={toast}
                            />
                        </FlexComponent>
                    ))}
                    <DragOverlay dropAnimation={defaultDropAnimation}>
                        <AppItem app={app} setBoardSections={setBoardSections} toast={toast} />
                    </DragOverlay>
                </FlexComponent>
            </DndContext>
        </div>
    );
};

export default AppSectionList;
